import { ErrorPage } from "@/components/common/ErrorPage/ErrorPage";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";

export default function Home() {
  const router = useRouter();

  if (!router.query["error"]) return null;

  return <ErrorPage />;
}

export const getServerSideProps: GetServerSideProps<{ token: string }> = async (
  context
) => {
  const hash = context?.params?.hash as string;
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_APP_URL}/api/storage?key=${hash}`
  );
  if (!res.ok) {
    return {
      redirect: {
        destination: `/error?type=forbidden`,
        permanent: false,
      },
    };
  }
  const data = await res.json();
  return { props: { token: data.value } };
};
